import {
  AssistanceRequestApi,
  AssistanceRequestFilesApi,
  AssistanceRequestLinkApi,
  BASE_PATH,
  ClaimApi,
  ClaimDamageAreaApi,
  ClaimDamageTemplateApi,
  ClaimFileApi,
  ClaimLinkApi,
  ClaimVehicleDetailsApi,
  Configuration,
  FeedbackApi,
  IframeApi,
  InsuranceProviderApi,
  OtherPartiesApi,
  PartnerWorkshopApi,
  ServiceNotificationApi,
  ServiceThemeApi,
  TowTruckApi,
  VehicleRepairCaseApi,
  VehiclesApi,
} from './api';
import { NetworkError } from './common/exception/NetworkError';

let basePath = BASE_PATH;

if (process.env.NODE_ENV !== 'production') {
  basePath = '/api/v1/public';
}

export const publicConfiguration = new Configuration({
  basePath,
  fetchApi: (input: RequestInfo, init?: RequestInit) =>
    fetch(input, init)
      .then((response) => response)
      .catch((error) => {
        if (error instanceof TypeError && error.message == 'Failed to fetch')
          throw new NetworkError('errors.networkProblem');
        throw error;
      }),
});

export const vehiclesApi = new VehiclesApi(publicConfiguration);
export const assistanceApi = new AssistanceRequestApi(publicConfiguration);
export const assistanceLinkApi = new AssistanceRequestLinkApi(publicConfiguration);
export const assistanceRequestFilesApi = new AssistanceRequestFilesApi(publicConfiguration);
export const towTruckApi = new TowTruckApi(publicConfiguration);
export const themeApi = new ServiceThemeApi(publicConfiguration);
export const claimApi = new ClaimApi(publicConfiguration);
export const claimLinkApi = new ClaimLinkApi(publicConfiguration);
export const claimFileApi = new ClaimFileApi(publicConfiguration);
export const claimVehicleDetails = new ClaimVehicleDetailsApi(publicConfiguration);
export const feedbackApi = new FeedbackApi(publicConfiguration);
export const assistanceRequestLinkApi = new AssistanceRequestLinkApi(publicConfiguration);
export const otherPartiesApi = new OtherPartiesApi(publicConfiguration);
export const claimDamageTemplateApi = new ClaimDamageTemplateApi(publicConfiguration);
export const claimDamageAreaApi = new ClaimDamageAreaApi(publicConfiguration);
export const vehicleRepairCaseApi = new VehicleRepairCaseApi(publicConfiguration);
export const partnerWorkshopApi = new PartnerWorkshopApi(publicConfiguration);
export const serviceNotificationApi = new ServiceNotificationApi(publicConfiguration);
export const insuranceProviderApi = new InsuranceProviderApi(publicConfiguration);
export const iframeProviderApi = new IframeApi(publicConfiguration);

export * from './api';
