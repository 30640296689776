/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiAssistanceRequestDestinationType,
    ApiAssistanceRequestDestinationTypeFromJSON,
    ApiAssistanceRequestDestinationTypeFromJSONTyped,
    ApiAssistanceRequestDestinationTypeToJSON,
} from './';

/**
 * Vehicle details
 * @export
 * @interface ApiDestination
 */
export interface ApiDestination {
    /**
     * 
     * @type {number}
     * @memberof ApiDestination
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiDestination
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDestination
     */
    address?: string;
    /**
     * 
     * @type {ApiAssistanceRequestDestinationType}
     * @memberof ApiDestination
     */
    type?: ApiAssistanceRequestDestinationType;
    /**
     * 
     * @type {number}
     * @memberof ApiDestination
     */
    workshopId?: number;
    /**
     * 
     * @type {Date}
     * @memberof ApiDestination
     */
    arrivalTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof ApiDestination
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiDestination
     */
    longitude?: number;
}

export function ApiDestinationFromJSON(json: any): ApiDestination {
    return ApiDestinationFromJSONTyped(json, false);
}

export function ApiDestinationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiDestination {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'type': !exists(json, 'type') ? undefined : ApiAssistanceRequestDestinationTypeFromJSON(json['type']),
        'workshopId': !exists(json, 'workshopId') ? undefined : json['workshopId'],
        'arrivalTime': !exists(json, 'arrivalTime') ? undefined : (new Date(json['arrivalTime'])),
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
    };
}

export function ApiDestinationToJSON(value?: ApiDestination | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'address': value.address,
        'type': ApiAssistanceRequestDestinationTypeToJSON(value.type),
        'workshopId': value.workshopId,
        'arrivalTime': value.arrivalTime === undefined ? undefined : (value.arrivalTime.toISOString()),
        'latitude': value.latitude,
        'longitude': value.longitude,
    };
}

