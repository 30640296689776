import React, { lazy, Suspense } from 'react';
import { history } from '../store/store';
import AssistanceRoute from './AssistanceRoute';
import ClaimRoute from './ClaimRoute';
import Landing from '../pages/landing/Landing';
import { AssistanceRoutes, ClaimRoutes, RouteName } from './RouteNames';
import AssistanceRequestPartnerLanding from '../pages/partner/assistance-request/AssistanceRequestPartnerLanding';
import AssistanceLinkValidation from '../pages/assistance-requests/link/LinkValidation';
import ClaimLinkValidation from '../pages/claims/link/LinkValidation';
import ClaimPartnerLanding from '../pages/partner/claims/ClaimPartnerLanding';
import { Loader } from '../components/loader/Loader';
import PartnerLanding from '../pages/partner/partnerLanding/PartnerLanding';
import FeedbackView from '../pages/feedback/FeedbackView';
import { Navigate, Route, Routes } from 'react-router-dom';
import { HistoryRouter } from 'redux-first-history/rr6';
import IframeLanding from '../pages/iframe/landing/IframeLanding';
import EditViewIframe from '../pages/iframe/editview/editViewIframe';

const CaseStatusView = lazy(() => import('../pages/cases/case-status/CaseStatus'));
const CaseProgressView = lazy(() => import('../pages/cases/case-progress/CaseProgress'));

export const Router: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <HistoryRouter history={history}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path='/iframe/:uuid' element={<IframeLanding />} />
          <Route path='/iframe/:uuid/edit' element={<EditViewIframe />} />
          <Route path='/assistance-request-link/:code' element={<AssistanceLinkValidation />} />
          <Route path='/claim-link/:code' element={<ClaimLinkValidation />} />
          <Route
            path='/partner/assistance-request/:serviceLinkName'
            element={<AssistanceRequestPartnerLanding />}
          />
          <Route path='/partner/claim/:serviceLinkName' element={<ClaimPartnerLanding />} />

          {ClaimRoutes.map((route: RouteName) => (
            <Route
              key={route.path}
              path={route.path}
              element={<ClaimRoute element={<route.component />} />}
            />
          ))}
          {AssistanceRoutes.map((route: RouteName) => (
            <Route
              key={route.path}
              path={route.path}
              element={<AssistanceRoute element={<route.component />} />}
            />
          ))}
          <Route path='/partner/:serviceLinkName' element={<PartnerLanding />} />
          <Route path='/cases/status' element={<CaseStatusView />} />
          <Route path='/cases/progress' element={<CaseProgressView />} />
          <Route path='/feedback/:uuid' element={<FeedbackView />} />
          <Route path='/' element={<Landing />} />
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
      </Suspense>
    </HistoryRouter>
  );
};
