/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiAssistanceRequestLinkResponse
 */
export interface ApiAssistanceRequestLinkResponse {
    /**
     * 
     * @type {number}
     * @memberof ApiAssistanceRequestLinkResponse
     */
    assistanceRequestId: number;
    /**
     * 
     * @type {string}
     * @memberof ApiAssistanceRequestLinkResponse
     */
    link: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAssistanceRequestLinkResponse
     */
    iframeLink?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAssistanceRequestLinkResponse
     */
    assistanceRequestExternalId: string;
    /**
     * 
     * @type {Date}
     * @memberof ApiAssistanceRequestLinkResponse
     */
    slaTime?: Date;
}

export function ApiAssistanceRequestLinkResponseFromJSON(json: any): ApiAssistanceRequestLinkResponse {
    return ApiAssistanceRequestLinkResponseFromJSONTyped(json, false);
}

export function ApiAssistanceRequestLinkResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAssistanceRequestLinkResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assistanceRequestId': json['assistanceRequestId'],
        'link': json['link'],
        'iframeLink': !exists(json, 'iframeLink') ? undefined : json['iframeLink'],
        'assistanceRequestExternalId': json['assistanceRequestExternalId'],
        'slaTime': !exists(json, 'slaTime') ? undefined : (new Date(json['slaTime'])),
    };
}

export function ApiAssistanceRequestLinkResponseToJSON(value?: ApiAssistanceRequestLinkResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assistanceRequestId': value.assistanceRequestId,
        'link': value.link,
        'iframeLink': value.iframeLink,
        'assistanceRequestExternalId': value.assistanceRequestExternalId,
        'slaTime': value.slaTime === undefined ? undefined : (value.slaTime.toISOString()),
    };
}

