import {
  ApiWorkshop,
  EditableIframeResponse,
  IframeInfoRequest,
  IframeLinkResponse,
  iframeProviderApi,
  SubmitIframeInfoRequest,
} from '../../apis';
import { ClientError } from '../../common/exception/ClientError';
import { linkValidationErrors } from '../assistance-requests/link/LinkValidation';

export const getIframeDetailsByUuid = (uuid: string) => async (): Promise<IframeLinkResponse> => {
  try {
    return await iframeProviderApi.getIframeInfo({
      uuid: uuid,
    });
  } catch (error) {
    throw new ClientError(linkValidationErrors.LINK_VALIDATION_FAILED);
  }
};

export const getEditableIframeDetailsByUuid =
  (uuid: string) => async (): Promise<EditableIframeResponse> => {
    try {
      return await iframeProviderApi.getSubmittedIframeInfo({
        uuid: uuid,
      });
    } catch (error) {
      throw new ClientError(linkValidationErrors.LINK_VALIDATION_FAILED);
    }
  };

export const getIframeWorkshops = (uuid: string) => async (): Promise<Array<ApiWorkshop>> => {
  try {
    return await iframeProviderApi.getWorkshopsByIframeUuid({
      uuid: uuid,
    });
  } catch (error) {
    throw new ClientError(linkValidationErrors.SORRY_SOMETHING_WENT_WRONG);
  }
};

export const updateIframeDetails = (uuid: string, info: IframeInfoRequest) => async () => {
  try {
    return await iframeProviderApi.updateIframeInfo({
      uuid: uuid,
      iframeInfoRequest: info,
    });
  } catch (error) {
    throw new ClientError(linkValidationErrors.SORRY_SOMETHING_WENT_WRONG);
  }
};

export const submitIframeDetails = (info: SubmitIframeInfoRequest) => async () => {
  try {
    return await iframeProviderApi.submitIframeInfo(info);
  } catch (error) {
    throw new ClientError(linkValidationErrors.SORRY_SOMETHING_WENT_WRONG);
  }
};
