/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EditableIframeResponse
 */
export interface EditableIframeResponse {
    /**
     * 
     * @type {Date}
     * @memberof EditableIframeResponse
     */
    slaTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof EditableIframeResponse
     */
    yawayId?: string;
    /**
     * 
     * @type {string}
     * @memberof EditableIframeResponse
     */
    region?: string;
    /**
     * 
     * @type {string}
     * @memberof EditableIframeResponse
     */
    service?: string;
}

export function EditableIframeResponseFromJSON(json: any): EditableIframeResponse {
    return EditableIframeResponseFromJSONTyped(json, false);
}

export function EditableIframeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditableIframeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'slaTime': !exists(json, 'slaTime') ? undefined : (new Date(json['slaTime'])),
        'yawayId': !exists(json, 'yawayId') ? undefined : json['yawayId'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'service': !exists(json, 'service') ? undefined : json['service'],
    };
}

export function EditableIframeResponseToJSON(value?: EditableIframeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'slaTime': value.slaTime === undefined ? undefined : (value.slaTime.toISOString()),
        'yawayId': value.yawayId,
        'region': value.region,
        'service': value.service,
    };
}

