/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiAssistanceIncidentType,
    ApiAssistanceIncidentTypeFromJSON,
    ApiAssistanceIncidentTypeFromJSONTyped,
    ApiAssistanceIncidentTypeToJSON,
    ApiAssistanceRequestStatus,
    ApiAssistanceRequestStatusFromJSON,
    ApiAssistanceRequestStatusFromJSONTyped,
    ApiAssistanceRequestStatusToJSON,
    ApiDestination,
    ApiDestinationFromJSON,
    ApiDestinationFromJSONTyped,
    ApiDestinationToJSON,
    ApiServiceThemeData,
    ApiServiceThemeDataFromJSON,
    ApiServiceThemeDataFromJSONTyped,
    ApiServiceThemeDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface IframeLinkResponse
 */
export interface IframeLinkResponse {
    /**
     * 
     * @type {Date}
     * @memberof IframeLinkResponse
     */
    agreedArrivalTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof IframeLinkResponse
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof IframeLinkResponse
     */
    address?: string;
    /**
     * 
     * @type {ApiAssistanceRequestStatus}
     * @memberof IframeLinkResponse
     */
    status?: ApiAssistanceRequestStatus;
    /**
     * 
     * @type {ApiAssistanceIncidentType}
     * @memberof IframeLinkResponse
     */
    incidentType?: ApiAssistanceIncidentType;
    /**
     * 
     * @type {ApiServiceThemeData}
     * @memberof IframeLinkResponse
     */
    theme?: ApiServiceThemeData;
    /**
     * 
     * @type {Array<ApiDestination>}
     * @memberof IframeLinkResponse
     */
    destinations?: Array<ApiDestination>;
}

export function IframeLinkResponseFromJSON(json: any): IframeLinkResponse {
    return IframeLinkResponseFromJSONTyped(json, false);
}

export function IframeLinkResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IframeLinkResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agreedArrivalTime': !exists(json, 'agreedArrivalTime') ? undefined : (new Date(json['agreedArrivalTime'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'status': !exists(json, 'status') ? undefined : ApiAssistanceRequestStatusFromJSON(json['status']),
        'incidentType': !exists(json, 'incidentType') ? undefined : ApiAssistanceIncidentTypeFromJSON(json['incidentType']),
        'theme': !exists(json, 'theme') ? undefined : ApiServiceThemeDataFromJSON(json['theme']),
        'destinations': !exists(json, 'destinations') ? undefined : ((json['destinations'] as Array<any>).map(ApiDestinationFromJSON)),
    };
}

export function IframeLinkResponseToJSON(value?: IframeLinkResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agreedArrivalTime': value.agreedArrivalTime === undefined ? undefined : (value.agreedArrivalTime.toISOString()),
        'description': value.description,
        'address': value.address,
        'status': ApiAssistanceRequestStatusToJSON(value.status),
        'incidentType': ApiAssistanceIncidentTypeToJSON(value.incidentType),
        'theme': ApiServiceThemeDataToJSON(value.theme),
        'destinations': value.destinations === undefined ? undefined : ((value.destinations as Array<any>).map(ApiDestinationToJSON)),
    };
}

