/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiAssistanceRequestLink,
    ApiAssistanceRequestLinkFromJSON,
    ApiAssistanceRequestLinkToJSON,
    ApiAssistanceRequestLinkResponse,
    ApiAssistanceRequestLinkResponseFromJSON,
    ApiAssistanceRequestLinkResponseToJSON,
    ApiAssistanceRequestLinkWithData,
    ApiAssistanceRequestLinkWithDataFromJSON,
    ApiAssistanceRequestLinkWithDataToJSON,
    ApiLinkValidation,
    ApiLinkValidationFromJSON,
    ApiLinkValidationToJSON,
    ApiPartialAssistanceRequestLinkResponse,
    ApiPartialAssistanceRequestLinkResponseFromJSON,
    ApiPartialAssistanceRequestLinkResponseToJSON,
    ApiServiceLink,
    ApiServiceLinkFromJSON,
    ApiServiceLinkToJSON,
} from '../models';

export interface InitializeAssistanceRequestRequest {
    apiAssistanceRequestLink: ApiAssistanceRequestLink;
}

export interface InitializeAssistanceRequestFromServiceLinkRequest {
    apiServiceLink: ApiServiceLink;
}

export interface InitializeAssistanceRequestWithDataRequest {
    apiAssistanceRequestLinkWithData: ApiAssistanceRequestLinkWithData;
}

export interface InitializeAssistanceRequestWithPartialDataRequest {
    apiAssistanceRequestLinkWithData: ApiAssistanceRequestLinkWithData;
}

export interface ValidateAssistanceRequestLinkRequest {
    code: string;
}

/**
 * 
 */
export class AssistanceRequestLinkApi extends runtime.BaseAPI {

    /**
     * Initializes a new assistance request and sends out the link for accessing it
     */
    async initializeAssistanceRequestRaw(requestParameters: InitializeAssistanceRequestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiAssistanceRequestLinkResponse>> {
        if (requestParameters.apiAssistanceRequestLink === null || requestParameters.apiAssistanceRequestLink === undefined) {
            throw new runtime.RequiredError('apiAssistanceRequestLink','Required parameter requestParameters.apiAssistanceRequestLink was null or undefined when calling initializeAssistanceRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/assistance-request/link`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiAssistanceRequestLinkToJSON(requestParameters.apiAssistanceRequestLink),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAssistanceRequestLinkResponseFromJSON(jsonValue));
    }

    /**
     * Initializes a new assistance request and sends out the link for accessing it
     */
    async initializeAssistanceRequest(requestParameters: InitializeAssistanceRequestRequest, initOverrides?: RequestInit): Promise<ApiAssistanceRequestLinkResponse> {
        const response = await this.initializeAssistanceRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Initializes a new assistance request using the service link
     */
    async initializeAssistanceRequestFromServiceLinkRaw(requestParameters: InitializeAssistanceRequestFromServiceLinkRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiAssistanceRequestLinkResponse>> {
        if (requestParameters.apiServiceLink === null || requestParameters.apiServiceLink === undefined) {
            throw new runtime.RequiredError('apiServiceLink','Required parameter requestParameters.apiServiceLink was null or undefined when calling initializeAssistanceRequestFromServiceLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/assistance-request/service-link`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiServiceLinkToJSON(requestParameters.apiServiceLink),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAssistanceRequestLinkResponseFromJSON(jsonValue));
    }

    /**
     * Initializes a new assistance request using the service link
     */
    async initializeAssistanceRequestFromServiceLink(requestParameters: InitializeAssistanceRequestFromServiceLinkRequest, initOverrides?: RequestInit): Promise<ApiAssistanceRequestLinkResponse> {
        const response = await this.initializeAssistanceRequestFromServiceLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Initializes a new assistance request with full details and sends out the link for accessing it
     */
    async initializeAssistanceRequestWithDataRaw(requestParameters: InitializeAssistanceRequestWithDataRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiAssistanceRequestLinkResponse>> {
        if (requestParameters.apiAssistanceRequestLinkWithData === null || requestParameters.apiAssistanceRequestLinkWithData === undefined) {
            throw new runtime.RequiredError('apiAssistanceRequestLinkWithData','Required parameter requestParameters.apiAssistanceRequestLinkWithData was null or undefined when calling initializeAssistanceRequestWithData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/assistance-request/full-data-link`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiAssistanceRequestLinkWithDataToJSON(requestParameters.apiAssistanceRequestLinkWithData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAssistanceRequestLinkResponseFromJSON(jsonValue));
    }

    /**
     * Initializes a new assistance request with full details and sends out the link for accessing it
     */
    async initializeAssistanceRequestWithData(requestParameters: InitializeAssistanceRequestWithDataRequest, initOverrides?: RequestInit): Promise<ApiAssistanceRequestLinkResponse> {
        const response = await this.initializeAssistanceRequestWithDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Initializes a new assistance request with partial details and sends out the link for accessing it
     */
    async initializeAssistanceRequestWithPartialDataRaw(requestParameters: InitializeAssistanceRequestWithPartialDataRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiPartialAssistanceRequestLinkResponse>> {
        if (requestParameters.apiAssistanceRequestLinkWithData === null || requestParameters.apiAssistanceRequestLinkWithData === undefined) {
            throw new runtime.RequiredError('apiAssistanceRequestLinkWithData','Required parameter requestParameters.apiAssistanceRequestLinkWithData was null or undefined when calling initializeAssistanceRequestWithPartialData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/assistance-request/partial-data-link`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiAssistanceRequestLinkWithDataToJSON(requestParameters.apiAssistanceRequestLinkWithData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiPartialAssistanceRequestLinkResponseFromJSON(jsonValue));
    }

    /**
     * Initializes a new assistance request with partial details and sends out the link for accessing it
     */
    async initializeAssistanceRequestWithPartialData(requestParameters: InitializeAssistanceRequestWithPartialDataRequest, initOverrides?: RequestInit): Promise<ApiPartialAssistanceRequestLinkResponse> {
        const response = await this.initializeAssistanceRequestWithPartialDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * validate assistance request link
     */
    async validateAssistanceRequestLinkRaw(requestParameters: ValidateAssistanceRequestLinkRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiLinkValidation>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling validateAssistanceRequestLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/assistance-request-link/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiLinkValidationFromJSON(jsonValue));
    }

    /**
     * validate assistance request link
     */
    async validateAssistanceRequestLink(requestParameters: ValidateAssistanceRequestLinkRequest, initOverrides?: RequestInit): Promise<ApiLinkValidation> {
        const response = await this.validateAssistanceRequestLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
