import { LanguageType } from './LanguageType';
import { ApiAssistanceIncidentType, ApiClaimIncidentType } from '../../api';

export const lv: LanguageType = {
  ok: 'Labi.',
  close: 'CLOSE',
  next: 'TURPINĀT',
  name: 'Vārds',
  submit: 'IESNIEGT',
  refresh: 'Atsvaidzināt',
  phoneNumber: 'Telefona numurs',
  selectCounty: 'Izvēlēties apgabalu',
  agreedArrivalTime: 'Saskaņotais ierašanās laiks',
  all: 'Visi',
  enterVehicleNumber: 'Ievadiet transportlīdzekļa reģistrācijas numuru',
  enterYourName: 'Ievadiet savu vārdu',
  enterYourPhoneNumber: 'Ievadiet savu telefona numuru',
  refreshPageToSeeUpdates:
    'Mēs esam atjauninājuši lapu. Lūdzu, atsvaidziniet lapu, lai redzētu jaunākās izmaiņas.',
  orderHelp: 'PASŪTĪT PALĪDZĪBU',
  fileAClaimDescription:
    'Ja ir noticis apdrošināšanas gadījums, atlīdzības pieteikumu aicinām iesniegt šeit vai BALTA mobilajā aplikācijā!',
  fileClaim: 'ATTIECĪBĀ UZ PRASĪBU',
  iframe: {
    incidentType: 'Incidenta veids',
    addManually: 'Pievienot manuāli',
    selectDestinationWorkshop: 'Izvēlieties galamērķa darbnīcu',
    destinationName: 'Galamērķa nosaukums',
    destinationAddress: 'Galamērķa adrese',
    destinationNameError: 'Galamērķa nosaukums ir obligāts!',
    pickupLocation: 'Pikšanas vieta',
    dateError: 'Datums un laiks jābūt nākotnē!',
    region: 'Reģions',
    service: 'Pakalpojums',
  },
  assistanceIncident: {
    WHAT_IS_THE_ISSUE: 'Kas ir noticis?',
    [ApiAssistanceIncidentType.EmptyBattery]: 'Tukšs/izlādējies akumulators',
    [ApiAssistanceIncidentType.RunOutOfFuel]: 'Izbeidzās degviela',
    [ApiAssistanceIncidentType.FlatTires]: 'Tukša riepa',
    [ApiAssistanceIncidentType.StuckInSandSnowMud]: 'Iestrēdzis smiltīs/sniegā/dubļos',
    [ApiAssistanceIncidentType.DoorLocked]: 'Aizbloķējušās durvis',
    [ApiAssistanceIncidentType.UnknownTechnicalIssue]: 'Nezināmas izcelsmes tehniska problēma',
    [ApiAssistanceIncidentType.TrafficAccident]: 'Satiksmes negadījums',
    [ApiAssistanceIncidentType.Other]: 'Cits',
  },
  claimIncident: {
    WHAT_IS_THE_ISSUE: 'Kas ir noticis?',
    [ApiClaimIncidentType.WindshieldDamage]: 'Vējstikla bojājums',
    [ApiClaimIncidentType.TrafficAccident]: 'Satiksmes negadījums',
    [ApiClaimIncidentType.HitWildAnimal]: 'Sadursme ar meža dzīvnieku',
    [ApiClaimIncidentType.TechnicalBreakdown]: 'Tehnisks bojājums',
    [ApiClaimIncidentType.Theft]: 'Zādzība',
    [ApiClaimIncidentType.VandalismOrFire]: 'Vandālisms vai degšana',
    [ApiClaimIncidentType.Other]: 'Cits',
    invalidClaim: 'Nederīgs pietekums!',
  },
  vehicleDetails: {
    mark: 'Marka',
    model: 'Modelis',
    year: 'Gads',
    error: 'Lai saņemtu palīdzību, sazinieties ar operatoru.',
    callOperator: 'Zvanīt',
    missingLicensePlate: 'Norādiet transporta līdzekļa numuru',
    invalidLicensePlate: 'Atļauti tikai cipari un burti.',
    missingName: 'Norādiet savu vārdu',
    missingPhoneNumber: 'Norādiet savu telefona numuru',
    requiredField: 'Obligātais lauks',
    invalidPhoneNumber: 'Jābūt skaitlim, kas sākas ar +',
  },
  findTowTruck: {
    message1: 'Operatoru informēšana',
    message2: 'Auto evakuatora  atrašana var aizņemt līdz 2 minutēm',
    message3: 'Notiek ģeolokācijas noteikšana',
    message4: 'Notiek tuvāko auto evakuatoru meklēšana',
    message5: 'Atrasti tuvākie auto evakuatori',
    message6: 'Informācija par negadījumu tiek pārsūtīta auto evakuatoriem',
    message7: 'Auto evakuatori izvērtē informāciju',
    message8: 'Gaida kamēr auto evakuators atbildēs',
  },
  waitForTowTruck: {
    title: 'Palīdzība jau ir ceļā !',
    driverComing: 'dodas palīgā',
    callDriver: 'Zvanīt vadītājam',
    driverShouldBeThere: 'Vadītājam jābūt klāt tuvākajā laikā',
    eta: 'Plānotais gaidīšanas laiks',
  },
  noTowTruckFound: {
    title: 'Atvainojamies! Sobrīd nav pieejams neviens evakuators',
    details: `Mūsu operatori ir informēti un zvanīs Jums, tiklīdz kādu atradīsim`,
  },
  towTruckJobFinished: {
    title: 'Darbs ir pabeigts',
  },
  map: {
    searchHint: 'Meklēt',
    error: 'Neizdevās sazināties ar serveri. Lūdzu, pārbaudiet tīkla savienojumu.',
    pin: 'Virziet karti, lai norādītu atrašanās vietu',
    pleaseSetPinToYourLocation: 'Lūdzu, atzīmējiet savu atrašanās vietu',
    unableToRetrieveLocation:
      'Atrašanās vietu neizdevās iegūt. Lūdzu, atrodiet atrašanās vietu kartē.',
    confirmLocation: {
      modalTitle: 'Apstiprināt adresi',
      modalBody: 'Lūdzu, apstipriniet, ka adrese ir <b>%{address}</b>.',
      confirm: 'Apstiprināt',
      cancel: 'Atcelt',
    },
    failedToRetrieveAddress: 'Neizdevās iegūt adresi. Lūdzu, mēģiniet vēlreiz.',
    failedToSaveLocation: 'Neizdevās saglabāt atrašanās vietu. Lūdzu, mēģiniet vēlreiz.',
  },
  countdown: {
    minutes: 'Minūtes',
    seconds: 'sekundes',
  },
  errors: {
    noVehicleFound: 'Transportlīdzeklis nav atrasts',
    tryAgain: 'Atvainojiet, kaut kas neizdevās. Mēģiniet vēlreiz vēlāk.',
    networkProblem:
      'Atklāts tīkla darbības pārtraukums. Lūdzu, pārliecinieties, ka savienojums darbojas, un mēģiniet vēlreiz.',
    failedToUpload:
      'Neizdevās augšupielādēt attēlus. Lūdzu, mēģiniet vēlreiz vai noņemiet fotoattēlus, lai turpinātu.',
    savingAccidentDetailsFailed:
      'Neizdevās saglabāt negadījuma informāciju. Lūdzu, mēģiniet vēlreiz.',
    claimNotFound: 'Pretenzija nav atrasta.',
    savingPreferencesFailed: 'Neizdevās saglabāt saziņas preferences. Lūdzu, mēģiniet vēlreiz.',
    claimSubmissionFailed: 'Neizdevās iesniegt pieprasījumu. Lūdzu, mēģiniet vēlreiz.',
  },
  additionalDetails: {
    addPhotos: 'Noklikšķiniet un pievienojiet fotogrāfijas',
    requiredField: 'Obligātais lauks',
    descriptionLabel: 'Apraksts',
    pictures: 'Attēli (nav obligāts)',
    email: 'E-pasts (nav obligāts)',
    invalidEmail: 'Nederīgs e-pasta formāts!',
    descriptionPlaceholder: {
      [ApiAssistanceIncidentType.EmptyBattery]:
        'Lūdzu, aprakstiet situāciju. Cik ilgi transportlīdzeklis ir stāvējis? Vai transportlīdzeklis atrodas garāžā vai pazemes stāvvietā?',
      [ApiAssistanceIncidentType.RunOutOfFuel]: 'Lūdzu, aprakstiet situāciju.',
      [ApiAssistanceIncidentType.FlatTires]:
        'Lūdzu, aprakstiet situāciju. Kura riepa ir plīsusi? Vai riepa ir bojāta no sāniem?',
      [ApiAssistanceIncidentType.DoorLocked]:
        'Lūdzu, aprakstiet situāciju. Kur ir automašīnas atslēgas? Kur ir rezerves atslēgas?',
      [ApiAssistanceIncidentType.StuckInSandSnowMud]: 'Lūdzu, aprakstiet situāciju.',
      [ApiAssistanceIncidentType.UnknownTechnicalIssue]:
        'Lūdzu, aprakstiet problēmu. Vai ir iespējams iedarbināt transportlīdzekli? Vai ir iespējams pārslēgt pārnesumkārbu neitrālā režīmā? Vai ir iespējams atvienot rokas bremzi? Vai stūres rats griežas?',
      [ApiAssistanceIncidentType.TrafficAccident]:
        'Lūdzu, aprakstiet negadījumu. Vai transportlīdzeklis ir vadāms? Vai negadījumā ir iesaistītas citas personas? Vai ir informēta policija?',
      [ApiAssistanceIncidentType.Other]: 'Lūdzu, aprakstiet situāciju.',
    },
  },
  landing: {
    welcome: 'Laipni aicināti Latvijā !',
    callYourInsuranceCompanyToGetLink:
      'Lūdzu, sazvaniet savu apdrošināšanas kompāniju, lai saņemtu SMS ar saiti uz veidlapu',
    openTheLinkAgain: 'Ja saņēmāt SMS, atveriet saiti vēlreiz',
  },
  linkValidation: {
    linkValidationFailed: 'Saites validācija neizdevās',
    linkHasExpired: 'Saites derīguma termiņš ir beidzies',
    linkAlreadyProcessed: 'Saite jau tika apstrādāta',
    invalidLink: 'Nederīga saite',
    sorrySomethingWentWrong: 'Atvainojamies, kaut kas neizdevās',
    callToGetNewLink: 'Zvaniet, lai iegūtu jaunu SMS saiti',
  },
  trackDriver: {
    warning: 'BRĪDINĀJUMS',
    towtruckDriverChanged: 'Jūsu lietu apkalpos cits autovadītājs!',
  },
  otherParties: {
    delete: 'Dzēst',
    missingValue: 'Trūkstošie lauki',
    title: 'Citas iesaistītās puses',
    otherPartiesInvolved: 'Vai bija iesaistītas citas puses?',
    otherPartiesInfo: 'Vai jums ir informācija par citām iesaistītajām pusēm?',
    otherPartiesType: 'Izvēlieties iesaistītās puses veidu',
    vehiclePartyType: 'Vai tas ir transporta līdzeklis?',
    objectPartyType: 'Vai tas ir kāds objekts?',
    vehicleParty: {
      number: 'Otras iesaistītās puses transportlīdzekļa numurs',
      name: 'Vārds (nav obligāts)',
      personalCode: 'Personas kods (nav obligāts)',
      phoneNumber: 'Telefona numurs (nav obligāts)',
      email: 'E-pasta adrese (nav obligāts)',
    },
    objectParty: {
      description: 'Aprakstiet otras iesasitītās puses objektu',
      name: 'Vārds (nav obligāts)',
      personalCode: 'Personas kods (nav obligāts)',
      phoneNumber: 'Telefona numurs (nav obligāts)',
      email: 'E-pasta adrese (nav obligāts)',
    },
    addAnotherParty: 'Pievienot citu iesaistīto pusi',
    yes: 'JĀ',
    no: 'NĒ',
    invalidPartyPresentErrorMessage:
      'Dažām pusēm trūkst nepieciešamās informācijas. Lūdzu, papildiniet informāciju vai dzēsiet partiju!',
  },
  notifierInfo: {
    title: 'INFORMĀCIJA PAR PAZIŅOTĀJU',
    driver: 'Vadītājs',
    name: 'Vārds Uzvārds',
    personalCode: 'Personas kods (pēc izvēles)',
    telephone: 'Telefona numurs',
    email: 'E-pasta adrese',
    confirmUserIsDriver: 'Vai jūs bijāt vadītājs negadījuma laikā?',
    notifyUserTofillDriverDetails: '(Ja nē, norādiet informāciju par vadītāju)',
    affirm: 'JĀ',
    deny: 'NĒ',
    errors: {
      name: 'Nepieciešams vārds uzvārds!',
      telephone: 'Tālruņa numurā var būt tikai cipari, un tas nedrīkst būt tukšs!',
      email: 'E-pasta adresei jābūt derīgai',
      personalCode: 'Personas kods ir jābūt pareizā formātā!',
    },
  },
  insuranceSelection: {
    title: 'Apdrošināšana',
    subtitle: 'Mēs nosūtīsim prasību apdrošinātājam',
    cascoSelect: 'Izvēlieties KASKO apdrošināšanu',
    mtplSelect: 'Izvēlieties OCTA apdrošināšanu',
    CascoNoneApplicable: 'Nav KASKO apdrošināšanas',
    MtplNotSure: 'Es nezinu',
    mandatorySelectionError: 'Izvēle ir obligāta!',
    casco: 'KASKO',
    mtpl: 'OCTA',
  },
  accidentDetails: {
    title: 'Ziņas par negadījumu',
    affirmation: 'JĀ',
    deny: 'NĒ',
    dateTime: 'Ievadiet negadījuma datumu un laiku',
    mandatoryLocation: 'Atrašanās vieta ir obligāta',
    location: 'Negadījuma atrašanās vieta',
    description: 'Negadījuma apraksts',
    culprit: {
      responsibleParty: 'Kas izraisīja negadījumu?',
      notifier: '%{licensePlate} ir iniciators',
      otherParty: 'Vainīgs bija kāds cits',
      unknown: 'Nezināms',
    },
    dateError: 'Nepieciešams datums un laiks!',
    authoritiesNotified: 'Vai tika informētas atbildīgās iestādes?',
    personalInjury: 'Ievainotie, ieskaitot viegli ievainotos?',
    accidentDateIsMandatory: 'Negadījuma datums ir obligāts',
    accidentTimeIsMandatory: 'Negadījuma laiks ir obligāts',
    accidentDescriptionIsMandatory: 'Negadījuma apraksts ir obligāts',
  },
  claimReceived: {
    title: 'PIETEIKUMS PIEŅEMTS',
    body: 'Gadījums ir veiksmīgi reģistrēts',
  },
  communicationPreference: {
    title: 'Priekšroka saziņai',
    email: 'E-PASTS',
    sms: 'SMS',
    error: 'Nepieciešams vismaz viens saziņas veids!',
    submit: 'IESNIEGT',
  },
  damageLocation: {
    title: 'Bojājuma vieta',
  },
  photos: {
    title: 'FOTOGRAFĒT',
    skipForNow: 'IZLAIST',
    skipStep: 'IZLAIST SOLI',
    takePhotos: 'FOTOGRAFĒT',
    uploadFromGallery: 'AUGŠUPIELĀDĒT NO GALERIJAS',
    photoUploadLimitReached:
      'Šai veidlapai var pievienot kopā %{number} attēlus. Pārējie ir izmesti',
    skipTitle: `Jūs gatavojaties izlaist soli "augšuplādēt fotogrāfijas". 
      Fotogrāfijas ir svarīgas, lai aizpildītu pieteikumu. Naizmirstiet pievienot tās vēlāk.`,
    turnYourPhoneHorizontally: 'Pagrieziet telefonu horizontāli',
    takeAPhotoOfDamage: 'Nofotografējiet bojājumu',
    uploadSuggestions: {
      generalViewOfTheVehicle: 'Transportlīdzekļa kopskats ar reģistrācijas numuru',
      damagePicture: 'Bojājumu attēli (tuvāk un tālāk)',
      availableDocuments: 'Saskaņotais paziņojums vai policijas protokols',
      driversLicense: 'Autovadītāja apliecības fotoattēls',
      scenePicture: 'Notikuma vietas attēli (ja ir)',
    },
  },
  partnerLanding: {
    title: 'Kā mēs varam palīdzēt?',
    fileAClaim: 'Pieprasījuma iesniegšana',
    roadsideAssistance: 'Palīdzība uz ceļa',
  },
  caseStatus: {
    title: 'Lietas statuss',
    registration: 'Reģistrācijas Nr.',
    steps: {
      caseRegistered: 'Reģistrēts gadījums',
      caseRegisteredDone: 'Reģistrēts gadījums',
      vehicleDeliveredToWorkshop: 'Uz darbnīcu piegādātais transportlīdzeklis',
      vehicleDeliveredToWorkshopDone: 'Uz darbnīcu piegādātais transportlīdzeklis',
      estimatedDiagnostics: 'Aprēķinātā diagnostika',
      estimatedDiagnosticsDone: 'Veikta diagnostika',
      preparingRepairEstimate: 'Remonta tāmes sagatavošana',
      preparingRepairEstimateDone: 'Pabeigta remonta tāme',
      confirmingRepairEstimate: 'Remonta tāmes apstiprināšana',
      confirmingRepairEstimateDone: 'Apstiprināta remonta tāme',
      estimatedArrivalOfSpareParts: 'Paredzamā rezerves daļu pienākšana',
      estimatedArrivalOfSparePartsDone: 'Rezerves daļas ieradās',
      repairingStarts: 'Remonta uzsākšana',
      repairingStartsDone: 'Uzsākts remonts',
      estimatedEndOfRepair: 'Paredzamais remonta beigas',
      estimatedEndOfRepairDone: 'Remonts beidzās',
      pickUpVehicle: 'Lūdzu, paņemiet transportlīdzekli',
      pickUpVehicleDone: 'Transportlīdzekļa paņemšana',
    },
    nextStep: 'Jūsu lieta tiek izskatīta. Nākamais solis ir',
    workshopDetails: 'Workshop Details',
    errors: {
      invalidLink: 'Nederīga saite',
      caseNotFound: 'Lieta nav atrasta',
    },
  },
  terms: {
    agreement: 'Piekrītu pakalpojuma noteikumiem un nosacījumiem, un privātuma politikai.',
    termsAndPrivacy: 'Noteikumi un konfidencialitātes politika',
    error: 'Jums ir jāpiekrīt noteikumiem un privātuma politikai',
    termsTitle: 'PAKALPOJUMA VISPĀRĪGIE NOTEIKUMI',
    privacyTitle: 'PRIVĀTUMA NOTEIKUMI',
  },
  workshop: {
    choose: 'IZVĒLĒTIES DARBNĪCU',
    hint: '<h6><b>Izvēlieties darbnīcu, noklikšķinot uz <img src = %{img} width="20px" height="20px" /> kartē</b></h6>',
  },
  feedback: {
    title: 'Jūsu atsauksmes ir svarīgas!',
    subText: 'Dariet mums zināmu, kāda bija jūsu kopējā pieredze.',
    emptyRatingText: 'Lūdzu, novērtējiet savu pieredzi.',
    commentLabel: 'Citi komentāri (nav obligāts)',
    expired: 'Saites derīguma termiņš ir beidzies!',
    notFound: 'Saite ir nederīga!',
    alreadyGiven: 'Jūs jau sniedzāt atsauksmi!',
    success: 'Paldies! Jūsu atsauksme ir veiksmīgi saņemta!',
    submissionError: 'Neizdevās iesniegt atsauksmi! Lūdzu, mēģiniet vēlreiz vēlāk.',
  },
  assistanceLanding: {
    title: 'Pasūtīt palīdzību uz ceļa',
    description:
      '<p>Pasūtīt palīdzību uz ceļa, noklikšķinot uz SĀKT pogas, ir par <b>75% ātrāk</b> nekā runāt ar operatoru.<br><br> Tas ir vienkāršs 4 soļu process, kas aizņem līdz 2 minūtēm. Jūsu atrašanās vieta tiek noteikta, izmantojot GPS.</p>',
    start: 'SĀKT',
  },
};
