import React, { useEffect, useState } from 'react';
import { Translate } from 'react-redux-i18n';
import { Container, Navbar } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import { TextAreaInput } from '../../../components/controls/TextAreaInput';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { NextButton } from '../../../components/controls/NextButton';
import { AppState } from '../../../store/store';
import { getLanguageEnumFromType } from '../../../locale/languageMap';
import { connect, ResolveThunks } from 'react-redux';
import CustomDateTimePicker from '../../../common/components/date-time-picker/CustomDateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { Box, InputLabel, MenuItem, Select } from '@mui/material';
import { ApiAssistanceIncidentType, ApiAssistanceRequestStatus, ApiWorkshop } from '../../../api';
import { isSupportedLocale, LanguageType } from '../../../locale/locale';
import { useParams } from 'react-router-dom';
import { getIframeDetailsByUuid, getIframeWorkshops, updateIframeDetails } from '../iframeActions';
import LanguageSelector from '../../../components/languageSelector/LanguageSelector';
import { ClientError } from '../../../common/exception/ClientError';
import { descriptionValidation } from '../../../common/FormValidations';
import { Loader } from '../../../components/loader/Loader';
import DestinationSelector from './destinationSelector';
import { setLocaleWithFallback } from '../../../locale/languageActions';
import { getLanguageParamFromUrl } from '../../../common/utils/URLUtils';
import Progress from '../../../components/controls/Progress';
import { push } from 'redux-first-history';

const validationSchema = yup.object().shape({
  description: descriptionValidation,
});

const getTranslatedIncidentTypes = () => {
  return Object.values(ApiAssistanceIncidentType);
};

type PathParameters = {
  uuid: string;
};

export const IframeLanding: React.FC<React.PropsWithChildren<IframeLandingProps>> = ({
  color,
  language,
  getIframeInfo,
  updateIframeDetails,
  getIframeWorkshopsByUuid,
  setLocale,
  navigateToPostSubmitScreen,
}) => {
  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const [initiated, setInitiated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [dateTime, setDateTime] = useState<Dayjs | null>(null);
  const [incident, setIncident] = useState('');
  const [description, setDescription] = useState('');
  const [workshops, setSuggestedWorkshops] = useState<ApiWorkshop[]>([]);

  const [address, setAddress] = useState('');
  const { uuid } = useParams<PathParameters>();
  const incidentTypes = getTranslatedIncidentTypes();
  const [landingSubmitted, setLandingSubmitting] = useState(false);
  const urlLanguage = getLanguageParamFromUrl();
  const [progress, setProgress] = useState(1);
  useEffect(() => {
    initialize();
    urlLanguage && isSupportedLocale(urlLanguage) && setLocale(urlLanguage);
  }, []);

  const initialize = async () => {
    if (uuid) {
      try {
        const iframe = await getIframeInfo(uuid);
        if (iframe.status != ApiAssistanceRequestStatus.Draft) navigateToPostSubmitScreen(uuid);

        if (iframe.incidentType) setIncident(iframe.incidentType);
        if (iframe.description) setDescription(iframe.description);
        if (iframe.agreedArrivalTime) setDateTime(dayjs(iframe.agreedArrivalTime));
        else setDateTime(null);
        if (iframe.address) setAddress(iframe.address);
      } catch (e) {
        if (e instanceof ClientError) {
          setError(true);
        }
      } finally {
        setInitiated(true);
      }
    }
  };

  const onSubmit = handleSubmit(async () => {
    setLoading(true);
    if (uuid) {
      await updateIframeDetails(uuid, {
        description: description,
        incidentType: incident as ApiAssistanceIncidentType,
        agreedArrivalTime: dateTime?.toDate(),
      });
      setSuggestedWorkshops(await getIframeWorkshopsByUuid(uuid));
      setProgress(2);
      setLandingSubmitting(true);
    }
    setLoading(false);
  });

  return (
    <>
      {!initiated && <Loader />}
      {initiated && (
        <div>
          <Navbar
            expand='lg'
            className='theme-border justify-content-between'
            style={{ borderLeft: `4px solid ${color}` }}
          >
            <img
              src='https://diggz46oa5qa0.cloudfront.net/ServiceTheme/MVP3/YAWAY.svg'
              style={{ maxHeight: 55, maxWidth: 150 }}
              alt='website logo'
            />
            <LanguageSelector />
          </Navbar>

          {!error && (
            <div className='yaway-container mt-5'>
              <Progress total={2} currentStep={progress} />
            </div>
          )}
          {!landingSubmitted && !error && (
            <Container className='content yaway-container mt-5' fluid>
              <FormProvider {...methods}>
                <Form id='iframeLandingForm' className='my-4' onSubmit={onSubmit}>
                  <Box>
                    <InputLabel className='input-lbl'>
                      <Translate value='iframe.incidentType' />
                    </InputLabel>
                    <Select
                      ref={register}
                      name='incidentType'
                      data-testid='incidentType'
                      variant='standard'
                      value={incident}
                      placeholder={incident}
                      onChange={(e) => setIncident(e.target.value)}
                      className='select-options-navbar-main-color'
                      fullWidth
                    >
                      {incidentTypes.map((value) => (
                        <MenuItem key={value} value={value}>
                          <Translate value={`assistanceIncident.${value}`} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>

                  <TextAreaInput
                    name='description'
                    label={<Translate value='additionalDetails.descriptionLabel' />}
                    ref={register}
                    value={description}
                    onChangeHandler={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      setDescription(e.target.value)
                    }
                    isInvalid={!!errors.description}
                    validationError={<Translate value='additionalDetails.requiredField' />}
                    testId='description'
                  />

                  <Form.Group className='my-4'>
                    <Form.Label className='input-lbl'>
                      <Translate value='agreedArrivalTime' />
                    </Form.Label>
                    <CustomDateTimePicker
                      data-testid='datapicker'
                      dateTime={dateTime}
                      onDateChange={(date) => {
                        if (date != null && date.isBefore(dayjs().add(1, 'hours'))) {
                          setDateTime(dayjs().add(1, 'hours'));
                        } else {
                          setDateTime(date);
                        }
                      }}
                      language={language}
                      minDateTime={dayjs().add(0.99, 'hours')}
                    />
                  </Form.Group>
                </Form>
              </FormProvider>

              <NextButton
                type='submit'
                color={color}
                form='iframeLandingForm'
                data-testid='submitBtn'
                loading={loading}
                text={<Translate value='next' />}
              />
            </Container>
          )}
          {landingSubmitted && uuid && (
            <DestinationSelector
              address={address}
              suggestedWorkshops={workshops}
              uuid={uuid}
            ></DestinationSelector>
          )}
          {error && (
            <Container className='content yaway-container mt-5' fluid>
              <div className='text-center'>
                <p className='text-center link-status-text'>
                  <Translate value='linkValidation.invalidLink' />
                </p>
              </div>
            </Container>
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ i18n }: AppState) => ({
  color: '#36BB7B',
  language: getLanguageEnumFromType(i18n.locale as LanguageType),
});

const mapDispatchToProp = {
  getIframeInfo: getIframeDetailsByUuid,
  updateIframeDetails: updateIframeDetails,
  setLocale: setLocaleWithFallback,
  getIframeWorkshopsByUuid: getIframeWorkshops,
  navigateToPostSubmitScreen: (uuid: string) => push(`/iframe/${uuid}/edit`),
};

export type IframeLandingProps = ReturnType<typeof mapStateToProps> &
  ResolveThunks<typeof mapDispatchToProp>;

export default connect(mapStateToProps, mapDispatchToProp)(IframeLanding);
