/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiWorkshop,
    ApiWorkshopFromJSON,
    ApiWorkshopToJSON,
    EditableIframeResponse,
    EditableIframeResponseFromJSON,
    EditableIframeResponseToJSON,
    IframeInfoRequest,
    IframeInfoRequestFromJSON,
    IframeInfoRequestToJSON,
    IframeLinkResponse,
    IframeLinkResponseFromJSON,
    IframeLinkResponseToJSON,
    IframeSubmissionRequest,
    IframeSubmissionRequestFromJSON,
    IframeSubmissionRequestToJSON,
} from '../models';

export interface GetIframeInfoRequest {
    uuid: string;
}

export interface GetSubmittedIframeInfoRequest {
    uuid: string;
}

export interface GetWorkshopsByIframeUuidRequest {
    uuid: string;
}

export interface SubmitIframeInfoRequest {
    uuid: string;
    iframeSubmissionRequest: IframeSubmissionRequest;
}

export interface UpdateIframeInfoRequest {
    uuid: string;
    iframeInfoRequest: IframeInfoRequest;
}

/**
 * 
 */
export class IframeApi extends runtime.BaseAPI {

    /**
     * get iframe link details
     */
    async getIframeInfoRaw(requestParameters: GetIframeInfoRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IframeLinkResponse>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling getIframeInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/iframe/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IframeLinkResponseFromJSON(jsonValue));
    }

    /**
     * get iframe link details
     */
    async getIframeInfo(requestParameters: GetIframeInfoRequest, initOverrides?: RequestInit): Promise<IframeLinkResponse> {
        const response = await this.getIframeInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get submitted iframe details
     */
    async getSubmittedIframeInfoRaw(requestParameters: GetSubmittedIframeInfoRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EditableIframeResponse>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling getSubmittedIframeInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/editable/iframe/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EditableIframeResponseFromJSON(jsonValue));
    }

    /**
     * get submitted iframe details
     */
    async getSubmittedIframeInfo(requestParameters: GetSubmittedIframeInfoRequest, initOverrides?: RequestInit): Promise<EditableIframeResponse> {
        const response = await this.getSubmittedIframeInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get workshops by iframe uuid
     */
    async getWorkshopsByIframeUuidRaw(requestParameters: GetWorkshopsByIframeUuidRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiWorkshop>>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling getWorkshopsByIframeUuid.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/iframe/{uuid}/workshops`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiWorkshopFromJSON));
    }

    /**
     * get workshops by iframe uuid
     */
    async getWorkshopsByIframeUuid(requestParameters: GetWorkshopsByIframeUuidRequest, initOverrides?: RequestInit): Promise<Array<ApiWorkshop>> {
        const response = await this.getWorkshopsByIframeUuidRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * submit iframe client and destination coordinates
     */
    async submitIframeInfoRaw(requestParameters: SubmitIframeInfoRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling submitIframeInfo.');
        }

        if (requestParameters.iframeSubmissionRequest === null || requestParameters.iframeSubmissionRequest === undefined) {
            throw new runtime.RequiredError('iframeSubmissionRequest','Required parameter requestParameters.iframeSubmissionRequest was null or undefined when calling submitIframeInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/iframe/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IframeSubmissionRequestToJSON(requestParameters.iframeSubmissionRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * submit iframe client and destination coordinates
     */
    async submitIframeInfo(requestParameters: SubmitIframeInfoRequest, initOverrides?: RequestInit): Promise<void> {
        await this.submitIframeInfoRaw(requestParameters, initOverrides);
    }

    /**
     * get iframe link details
     */
    async updateIframeInfoRaw(requestParameters: UpdateIframeInfoRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling updateIframeInfo.');
        }

        if (requestParameters.iframeInfoRequest === null || requestParameters.iframeInfoRequest === undefined) {
            throw new runtime.RequiredError('iframeInfoRequest','Required parameter requestParameters.iframeInfoRequest was null or undefined when calling updateIframeInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/iframe/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IframeInfoRequestToJSON(requestParameters.iframeInfoRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * get iframe link details
     */
    async updateIframeInfo(requestParameters: UpdateIframeInfoRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateIframeInfoRaw(requestParameters, initOverrides);
    }

}
