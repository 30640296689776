/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiAssistanceIncidentType,
    ApiAssistanceIncidentTypeFromJSON,
    ApiAssistanceIncidentTypeFromJSONTyped,
    ApiAssistanceIncidentTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface IframeInfoRequest
 */
export interface IframeInfoRequest {
    /**
     * 
     * @type {Date}
     * @memberof IframeInfoRequest
     */
    agreedArrivalTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof IframeInfoRequest
     */
    description: string;
    /**
     * 
     * @type {ApiAssistanceIncidentType}
     * @memberof IframeInfoRequest
     */
    incidentType: ApiAssistanceIncidentType;
}

export function IframeInfoRequestFromJSON(json: any): IframeInfoRequest {
    return IframeInfoRequestFromJSONTyped(json, false);
}

export function IframeInfoRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): IframeInfoRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agreedArrivalTime': !exists(json, 'agreedArrivalTime') ? undefined : (new Date(json['agreedArrivalTime'])),
        'description': json['description'],
        'incidentType': ApiAssistanceIncidentTypeFromJSON(json['incidentType']),
    };
}

export function IframeInfoRequestToJSON(value?: IframeInfoRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agreedArrivalTime': value.agreedArrivalTime === undefined ? undefined : (value.agreedArrivalTime.toISOString()),
        'description': value.description,
        'incidentType': ApiAssistanceIncidentTypeToJSON(value.incidentType),
    };
}

