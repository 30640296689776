/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IframeSubmissionRequest
 */
export interface IframeSubmissionRequest {
    /**
     * 
     * @type {string}
     * @memberof IframeSubmissionRequest
     */
    pickupLocationName?: string;
    /**
     * 
     * @type {number}
     * @memberof IframeSubmissionRequest
     */
    clientLatitude?: number;
    /**
     * 
     * @type {number}
     * @memberof IframeSubmissionRequest
     */
    clientLongitude?: number;
    /**
     * 
     * @type {number}
     * @memberof IframeSubmissionRequest
     */
    workshopId?: number;
    /**
     * 
     * @type {string}
     * @memberof IframeSubmissionRequest
     */
    destinationName?: string;
    /**
     * 
     * @type {string}
     * @memberof IframeSubmissionRequest
     */
    destinationAddress?: string;
    /**
     * 
     * @type {number}
     * @memberof IframeSubmissionRequest
     */
    destinationLatitude?: number;
    /**
     * 
     * @type {number}
     * @memberof IframeSubmissionRequest
     */
    destinationLongitude?: number;
}

export function IframeSubmissionRequestFromJSON(json: any): IframeSubmissionRequest {
    return IframeSubmissionRequestFromJSONTyped(json, false);
}

export function IframeSubmissionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): IframeSubmissionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pickupLocationName': !exists(json, 'pickupLocationName') ? undefined : json['pickupLocationName'],
        'clientLatitude': !exists(json, 'clientLatitude') ? undefined : json['clientLatitude'],
        'clientLongitude': !exists(json, 'clientLongitude') ? undefined : json['clientLongitude'],
        'workshopId': !exists(json, 'workshopId') ? undefined : json['workshopId'],
        'destinationName': !exists(json, 'destinationName') ? undefined : json['destinationName'],
        'destinationAddress': !exists(json, 'destinationAddress') ? undefined : json['destinationAddress'],
        'destinationLatitude': !exists(json, 'destinationLatitude') ? undefined : json['destinationLatitude'],
        'destinationLongitude': !exists(json, 'destinationLongitude') ? undefined : json['destinationLongitude'],
    };
}

export function IframeSubmissionRequestToJSON(value?: IframeSubmissionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pickupLocationName': value.pickupLocationName,
        'clientLatitude': value.clientLatitude,
        'clientLongitude': value.clientLongitude,
        'workshopId': value.workshopId,
        'destinationName': value.destinationName,
        'destinationAddress': value.destinationAddress,
        'destinationLatitude': value.destinationLatitude,
        'destinationLongitude': value.destinationLongitude,
    };
}

