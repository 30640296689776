/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiPartialAssistanceRequestLinkResponse
 */
export interface ApiPartialAssistanceRequestLinkResponse {
    /**
     * 
     * @type {number}
     * @memberof ApiPartialAssistanceRequestLinkResponse
     */
    assistanceRequestId: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPartialAssistanceRequestLinkResponse
     */
    iframeLink: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartialAssistanceRequestLinkResponse
     */
    assistanceRequestExternalId: string;
}

export function ApiPartialAssistanceRequestLinkResponseFromJSON(json: any): ApiPartialAssistanceRequestLinkResponse {
    return ApiPartialAssistanceRequestLinkResponseFromJSONTyped(json, false);
}

export function ApiPartialAssistanceRequestLinkResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiPartialAssistanceRequestLinkResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assistanceRequestId': json['assistanceRequestId'],
        'iframeLink': json['iframeLink'],
        'assistanceRequestExternalId': json['assistanceRequestExternalId'],
    };
}

export function ApiPartialAssistanceRequestLinkResponseToJSON(value?: ApiPartialAssistanceRequestLinkResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assistanceRequestId': value.assistanceRequestId,
        'iframeLink': value.iframeLink,
        'assistanceRequestExternalId': value.assistanceRequestExternalId,
    };
}

