import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Container, Form } from 'react-bootstrap';
import {
  LocationInput,
  LocationInputHandlerParam,
} from '../../../components/controls/LocationInputReactGeosuggest';
import { Translate } from 'react-redux-i18n';
import { ApiWorkshop } from '../../../api';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { TextInput } from '../../../components/controls/TextInput';
import { NextButton } from '../../../components/controls/NextButton';
import { submitIframeDetails } from '../iframeActions';
import { connect, ResolveThunks } from 'react-redux';
import { push } from 'redux-first-history';

const mapDispatchToProps = {
  submitIframe: submitIframeDetails,
  navigateToPostSubmitScreen: (uuid: string) => push(`/iframe/${uuid}/edit`),
};

interface LocationFormProps {
  uuid: string;
  address: string;
  suggestedWorkshops: ApiWorkshop[];
}

type DestinationSelectorProps = LocationFormProps & ResolveThunks<typeof mapDispatchToProps>;

const DestinationSelector: React.FC<DestinationSelectorProps> = ({
  uuid,
  address,
  suggestedWorkshops,
  submitIframe,
  navigateToPostSubmitScreen,
}) => {
  const methods = useForm();

  const [destination, setDestination] = useState('');
  const [destinationName, setDestinationName] = useState('');
  const [loading, setLoading] = useState(false);
  const [isManualSelection, setIsManualSelection] = useState(false);
  const [isLocationInvalid, setLocationInvalid] = useState(false);
  const [isDestinationLocationInvalid, setDestinationLocationInvalid] = useState(false);
  const [isDestinationNameInvalid, setDestinationNameInvalid] = useState(false);
  const [pickupLocation, setPickupLocation] = useState(address);
  const [workshopId, setWorkshopId] = useState<number | undefined>();
  const [destinationOptionError, setDestinationOptionError] = useState(false);
  const [optionSelected, setOptionSelected] = useState(false);
  const [location, setLocation] = useState({ latitude: 0, longitude: 0, label: '' });
  const [destinationLocation, setDestinationLocation] = useState({
    latitude: 0,
    longitude: 0,
    label: '',
  });

  const handleLocationInvalid = (newAddress: string) => {
    setLocationInvalid(!newAddress || newAddress.trim().length === 0);
  };

  const handleDestinationLocationInvalid = (newAddress: string) => {
    setDestinationLocationInvalid(!newAddress || newAddress.trim().length === 0);
  };

  const onLocationSelect = (data: LocationInputHandlerParam) => {
    setLocation(data);
    setPickupLocation(data.label);
    handleLocationInvalid(data.label);
  };

  const onDestinationLocationSelect = (data: LocationInputHandlerParam) => {
    setDestinationLocation(data);
    handleDestinationLocationInvalid(data.label);
  };

  const handleSubmit = async () => {
    let earlyReturn = false;
    if (!location.label && pickupLocation == location.label) {
      setLocationInvalid(true);
      earlyReturn = true;
    }
    if (!optionSelected) {
      setDestinationOptionError(true);
      earlyReturn = true;
    }
    if (isManualSelection && destinationName.trim().length === 0) {
      setDestinationNameInvalid(true);
      if (!destinationLocation.label) {
        setDestinationLocationInvalid(true);
      }
      earlyReturn = true;
    } else {
      setDestinationNameInvalid(false);
    }

    if (!workshopId && !destinationLocation.label) {
      setDestinationLocationInvalid(true);
      earlyReturn = true;
    }
    if (earlyReturn) return;
    setLoading(true);

    await submitIframe({
      uuid,
      iframeSubmissionRequest: {
        pickupLocationName: pickupLocation,
        clientLatitude: location.latitude,
        clientLongitude: location.longitude,
        workshopId,
        destinationName,
        destinationAddress: destinationLocation.label,
        destinationLatitude: destinationLocation.latitude,
        destinationLongitude: destinationLocation.longitude,
      },
    });
    navigateToPostSubmitScreen(uuid);
    setLoading(false);
  };

  const beforeSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    methods.handleSubmit(handleSubmit)(event);
  };

  const handleWorkshopSelection = (event: SelectChangeEvent) => {
    setOptionSelected(true);
    setDestinationOptionError(false);
    const selection = event.target.value;
    if (selection === '-1') {
      setIsManualSelection(true);
      setWorkshopId(undefined);
    } else {
      setIsManualSelection(false);
      setDestinationName('');
      setWorkshopId(parseInt(selection, 10));
    }
    setDestination(selection);
    setDestinationLocationInvalid(false);
  };

  return (
    <Container className='content yaway-container mt-5' fluid>
      <FormProvider {...methods}>
        <Form id='destinationDetailsForm' className='my-4' noValidate onSubmit={beforeSubmit}>
          <Form.Group className='my-4'>
            <div className={isLocationInvalid ? 'is-invalid' : ''}>
              <LocationInput
                label={<Translate value={'iframe.pickupLocation'} />}
                onSelect={onLocationSelect}
                value={pickupLocation}
                data-testId='pickupLocation'
              />
            </div>
            {isLocationInvalid && (
              <div className='invalid-feedback d-block'>
                <Translate value={'accidentDetails.mandatoryLocation'} />
              </div>
            )}
          </Form.Group>

          <Form.Group>
            <FormControl fullWidth>
              <InputLabel className='input-lbl'>
                <Translate value={'iframe.selectDestinationWorkshop'} />
              </InputLabel>
              <Select
                variant='standard'
                value={destination}
                onChange={handleWorkshopSelection}
                className='select-options-navbar-main-color'
                error={destinationOptionError}
                data-testId='dropdown'
              >
                <MenuItem data-testId='addManually' key='-1' value='-1'>
                  <Translate value={'iframe.addManually'} />
                </MenuItem>
                {suggestedWorkshops?.map((option) => (
                  <MenuItem key={option.id} value={option.id.toString()}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Form.Group>

          {isManualSelection && (
            <>
              <Form.Group>
                <TextInput
                  name='destinationName'
                  onChangeHandler={(e: { target: { value: React.SetStateAction<string> } }) =>
                    setDestinationName(e.target.value)
                  }
                  label={<Translate value={'iframe.destinationName'} />}
                  testId='name'
                  controlId='name'
                  isInvalid={isDestinationNameInvalid}
                  validationError={<Translate value={'iframe.destinationNameError'} />}
                />
              </Form.Group>

              <Form.Group className='my-4'>
                <div className={isDestinationLocationInvalid ? 'is-invalid' : ''}>
                  <LocationInput
                    label={<Translate value={'iframe.destinationAddress'} />}
                    onSelect={onDestinationLocationSelect}
                    value={destinationLocation.label || ''}
                  />
                </div>
                {isDestinationLocationInvalid && (
                  <div className='invalid-feedback d-block'>
                    <Translate value={'accidentDetails.mandatoryLocation'} />
                  </div>
                )}
              </Form.Group>
            </>
          )}
        </Form>

        <NextButton
          type='submit'
          color='#36BB7B'
          form='destinationDetailsForm'
          data-testid='submitBtn'
          loading={loading}
          text={<Translate value='submit' />}
        />
      </FormProvider>
    </Container>
  );
};

export default connect(null, mapDispatchToProps)(DestinationSelector);
